<template>
  <div class="detail-media">
    <div
      class="detail-media-item"
      v-for="(mediaItem, index) in showMediaList"
      :key="index"
      @click="openMedia(mediaItem)"
    >
      <div class="detail-media-box">
        <img
          v-if="mediaItem.type !== 'VIDEO'"
          class="cover-img"
          :src="
            showImgCover(
              mediaItem.file_url,
              mediaItem.type,
              mediaItem.file_suffix
            )
          "
          alt="cover-img"
        />
        <!-- <van-image
          v-if="mediaItem.type !== 'VIDEO'"
          width="108px"
          height="108px"
          fit="fill"
          :src="
            showImgCover(
              mediaItem.file_url,
              mediaItem.type,
              mediaItem.file_suffix
            )
          "
        /> -->
        <video
          class="cover-img"
          v-else
          fit="fill"
          :src="mediaItem.file_url"
        ></video>

        <img
          v-if="showIconCover(mediaItem)"
          class="play-icon"
          :src="require('@/assets/img/quickSend/' + mediaItem.type + '_2x.svg')"
          alt="paly"
        />
        <div
          v-if="['LINK', 'MINIPROGRAM', 'FILE'].includes(mediaItem.type)"
          class="media-item-title"
        >
          {{
            mediaItem.title ||
            mediaItem.qw_link_title ||
            mediaItem.qw_miniprogram_title ||
            mediaItem.file_name
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview, Image as VanImage, Switch } from 'vant'
import { openMiniProgram } from '../../utils/nwechat.js'
import { formatTaskItem } from '@/utils/sendMessage'

export default {
  name: 'MediaGride',
  components: {
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  props: {
    mediaList: {
      type: Array,
      default() {
        return []
      }
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    },
    pageType: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      member_userid: '',
      customer_userid: ''
    }
  },

  created() {
    // this.formatType()
    this.formatInit()
    // console.log('媒体列表', this.mediaList)
  },

  methods: {
    // 初始格式化数据
    formatInit() {
      // this.sendItem
      if (this.pageType === 'task') {
        this.showMediaList = formatTaskItem(this.mediaList)
      } else {
        this.showMediaList = this.mediaList
      }
    },

    // 点击媒体
    async openMedia(item) {
      // console.log('触发')
      if (item.type == 'IMAGE') {
        ImagePreview({
          images: [item.file_url],
          closeable: true
        })
      } else if (item.type == 'VIDEO') {
        window.open(item.file_url)
      } else if (item.type == 'MINIPROGRAM') {
        await openMiniProgram({
          appid: item.qw_miniprogram_appid,
          path: item.qw_miniprogram_page
        })
        // console.log('打开小程序')
      } else if (item.type == 'LINK') {
        window.open(item.qw_link_url)
      } else if (item.type == 'FILE') {
        // console.log('触发文件预览')
        let url = `/preview-page?file=${item.file_url}&type=${item.file_suffix}`
        window.open(url)
      }
    },

    //封面图片过滤
    showImgCover(url, type, file_suffix) {
      if (!url && type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      }
      if (!url) {
        return ''
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/word_2x.svg')
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/pdf_2x.svg')
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/excel_2x.svg')
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/ppt_2x.svg')
      }
      let baseUrl = url.indexOf('http') === -1 ? 'https://' : ''
      return baseUrl + url
    },

    // 附件封面上的图标展示
    showIconCover(item) {
      if (['VIDEO', 'MINIPROGRAM', 'LINK'].includes(item.type)) {
        if (item.type == 'LINK' && !item.file_url) return false
        return true
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
.detail-media {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3px;
  margin: 0 -5px;
  // justify-content: space-between;
  // .detail-media-item {
  //   width: 33.3%;
  // }
  // .detail-media-box-out {
  .detail-media-item {
    box-sizing: border-box;
    width: 33.33%;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    // display: flex;
    position: relative;

    .detail-media-box {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      border-radius: 5px;
      border: 1px solid #f5f5f5;
      overflow: hidden;
    }

    .cover-img {
      width: 100%;
      height: 100%;
      position: absolute;
      display: inline-block;
      object-fit: fill;
      border-radius: 5px;
    }

    .play-icon {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 36px;
      height: 36px;
      // opacity: 0.8;
      z-index: 5;
    }
  }

  .media-item-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    text-align: center;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.4);
  }
}
</style>
